import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Contact = () => {
  const intl = useIntl()

  const useInput = initialValue => {
    const [value, set] = useState(initialValue)
    return {
      value,
      onChange: e => {
        set(e.target.value)
      },
    }
  }

  const name = useInput("")
  const email = useInput("")
  const message = useInput("")

  // ボタンの活性・非活性制御
  let result = name.value && email.value && message.value
  let buttonClass = result
    ? "w-full md:max-w-xs border border-gray-400 bg-teal-500 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white py-2 px-4 rounded"
    : "w-full md:max-w-xs border border-gray-400 bg-gray-300 cursor-not-allowed text-white py-2 px-4 rounded"

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "category.contact" })} />
      <section className="w-full md:w-3/4 flex flex-col items-center px-3">
        <article className="w-full flex flex-col border border-gray-400 mb-3 bg-white rounded">
          <div className="flex flex-col justify-start p-6">
            <h1 className="text-3xl font-bold pb-6">
              {intl.formatMessage({ id: "contact.thanks" })}
            </h1>
            <p className="pt-3 pb-6">
              {intl.formatMessage({ id: "contact.sent" })}
            </p>
          </div>
        </article>
      </section>
    </Layout>
  )
}
export default Contact
